let url = "";

if (process.env.NODE_ENV === 'development') {
    url = "http://localhost:8000";
} else {
    url = "https://api.chegaki.laonlabs.com";
}

export const Constants = {
    url: url,
    baseUrl: url + "/api/admin",
    whatsappLink: "https://api.whatsapp.com/send?phone=554298190145&text=Ol%C3%A1!%20",
};
