import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
    import("./dashboard/DashboardPage")
);

const GrupoPage = lazy(() =>
    import("./grupo/GrupoPage")
);

const UsuarioPage = lazy(() =>
    import("./usuario/UsuarioPage")
);

const RelatorioPage = lazy(() =>
    import("./relatorio/RelatorioPage")
);

const DenunciaPage = lazy(() =>
    import("./denuncia/DenunciaPage")
);

const CategoriaPage = lazy(() =>
    import("./categoria/CategoriaPage")
);

const AnunciantePage = lazy(() =>
    import("./anunciante/AnunciantePage")
);

export default function HomePage() {

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    <Redirect exact from="/" to="/dashboard" />
                }

                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/grupo" component={GrupoPage} />
                <Route path="/usuario" component={UsuarioPage} />
                <Route path="/relatorio" component={RelatorioPage} />
                <Route path="/denuncia" component={DenunciaPage} />
                <Route path="/categoria" component={CategoriaPage} />
                <Route path="/anunciante" component={AnunciantePage} />

                <Redirect to="/error/error-v6" />
            </Switch>
        </Suspense>
    );
}
