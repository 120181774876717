import axios from "axios";
import { Constants } from '../utils/Constants';

export function login(email, password) {
  return axios.post(`${Constants.baseUrl}/auth`, {
    email: email,
    password: password
  });
}

export function getUserByToken() {
  return axios.get(`${Constants.baseUrl}/getUsuario`);
}

export function logout() {
  return axios.get(`${Constants.baseUrl}/logout`);
}
