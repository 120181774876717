
import axios from "axios";
import { Constants } from '../utils/Constants';

export function getDataTable(events) {
  return axios.post(`${Constants.baseUrl}/admin/list`, events);
}

export function create(form) {
  return axios.post(`${Constants.baseUrl}/admin`, form);
}

export function get(id) {
  return axios.get(`${Constants.baseUrl}/admin/${id}`);
}

export function update(form, id) {
  return axios.put(`${Constants.baseUrl}/admin/${id}`, form);
}

export function destroy(id) {
  return axios.delete(`${Constants.baseUrl}/admin/${id}`);
}

export function forgot(form) {
  return axios.post(`${Constants.url}/api/password/forgot`, form);
}

export function reset(form) {
  return axios.post(`${Constants.url}/api/password/reset`, form);
}