import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import BetterBox from '../../components/BetterBox';
import BetterAlerts from '../../components/BetterAlerts';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { reset } from '../../crud/admin.crud';
import { TextField } from "@material-ui/core";
import { Constants } from '../../utils/Constants';
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import queryString from 'query-string';

class ResetPassword extends Component {
  constructor() {
    super();

    this.state = {
      token: '',
      password: '',
      confirm: '',
      loading: false,
      submitted: false,
    };
  }

  componentDidMount = () => {
    let params = queryString.parse(this.props.location.search);
    this.setState({ token: params.token });
  };

  onSubmit = () => {
    this.setState(({ submitted: true }));

    if(this.state.password != this.state.confirm) {
      Swal.fire('Ops!', 'As senhas não são iguais', 'error');
      this.setState(({ submitted: false }));
      return;
    }

    if(this.state.password.length < 6) {
      Swal.fire('Ops!', 'Sua nova senha precisa ter no mínimo 6 caracteres.', 'error');
      this.setState(({ submitted: false }));
      return;
    }

    reset({password: this.state.password, token: this.state.token}).then(res => {
      window.scrollTo(0, 0);

      if (res.data.error) {
        this.setState({ submitted: false });
        Swal.fire('Ops!', res.data.error, 'error');
      } else {
        this.setState({ submitted: false });
        Swal.fire('Tudo certo!', 'Sua senha foi redefinida!', 'success');
      }
    }).catch((e) => {
      alert("Parece que houve um problema ao enviar o e-mail. Por favor, entre em contato com o suporte.");
    });
  };

  render() {
    const formControlStyle = {
      borderRadius: '5px',
      height: '50px',
      fontWeight: '600',
      fontSize: '21px',
      textAlign: 'center',
      color: '#424242',
    };

    const helperStyle = {
      color: 'red',
      fontSize: '13px',
      textAlign: 'center',
      marginTop: '5px'
    };

    return (
      <div style={{ margin: '30px 0px 30px 0px' }}>
        <div className="row" style={{ margin: '0' }}>
          <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div className="row">
              <div className="col-sm-12">
                <center>
                  <img alt="Logo" src={toAbsoluteUrl("/images/logo-dark.png")} style={{ width: '250px', height: 'auto', marginTop: '30px', marginBottom: '35px' }} />

                  <p style={{ padding: '10px 50px 15px 50px', fontSize: '18px', color: '#424242', fontWeight: '500' }}>
                    <b>Olá!</b> Para recuperar seu acesso, digite a nova senha.
                  </p>

                  <p style={{ padding: '10px 50px 20px 50px', fontSize: '15px', color: '#424242' }}>
                    Dificuldades com esse formulário? Entre em contato com nosso suporte pelo WhatsApp <a target="_blank" rel="noopener noreferrer" href={Constants.whatsappLink}>clicando aqui.</a>
                  </p>
                </center>
              </div>

              <div className="col-sm-12">
                <BetterCircularProgress loading={this.state.loading}>
                  <div>
                    <div className="col-sm-12">
                      <BetterAlerts errors={this.state.errors} />
                    </div>

                    <div>
                      <div className="col-sm-12">
                        <BetterBox title="Digite sua nova senha">
                          <div className="col-sm-12">
                            <div className='form-group fg-line'>
                              <TextField type="password" name="password" label="Senha *" margin="normal" variant="outlined"
                                  onBlur={(event) => this.setState({ password: event.target.value })} onChange={(event) => this.setState({ password: event.target.value })}
                              />
                              <TextField type="password" name="confirm" label="Confirmar senha *" margin="normal" variant="outlined"
                                  onBlur={(event) => this.setState({ confirm: event.target.value })} onChange={(event) => this.setState({ confirm: event.target.value })}
                              />
                            </div>
                          </div>
                        </BetterBox>
                      </div>

                      <div className="col-sm-12">
                        <center>
                          <br />
                          <Button className="btn btn-primary btn-lg btn-bold" onClick={() => this.onSubmit()}>
                            <i className={`margin-icon ${this.state.submitted ? "fas fa-sync fa-spin" : "fa fa-check-square"}`}></i>Enviar!
                          </Button>
                         
                          <br /><br />

                          <Link to="/auth/login" className="btn btn-label-brand btn-sm btn-bold">Voltar</Link>
                        </center>
                      </div>
                    </div>
                  </div>
                </BetterCircularProgress>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(ResetPassword)