import { Constants } from '../../app/utils/Constants';

export default {
    header: {
        self: {},
        items: [
            {
                title: "Suporte",
                root: true,
                alignment: "left",
                link: Constants.whatsappLink,
            },
        ]
    },
    aside: {
        self: {},
        items: [
            {
                title: "Dashboard",
                root: true,
                icon: "fas fa-tachometer-alt",
                page: "dashboard",
                bullet: "dot"
            },
            {
                title: "Anunciantes",
                root: true,
                icon: "fas fa-store",
                page: "anunciante",
                bullet: "dot"
            },
            {
                title: "Denúncias",
                root: true,
                icon: "fas fa-bullhorn",
                bullet: "dot",
                submenu: [
                    {
                        title: "Anúncios",
                        bullet: "dot",
                        page: "denuncia/anuncio"
                    },
                    {
                        title: "Comentários",
                        bullet: "dot",
                        page: "denuncia/comentario"
                    }
                ]
            },
            {
                title: "Relatórios",
                root: true,
                icon: "fas fa-chart-pie",
                bullet: "dot",
                submenu: [
                    {
                        title: "01 - Financeiro",
                        bullet: "dot",
                        page: "relatorio/relatorio01"
                    }
                ]
            },
            {
                title: "Cadastros",
                root: true,
                bullet: "dot",
                icon: "fas fa-list-alt",
                submenu: [
                    {
                        title: "Categoria",
                        bullet: "dot",
                        page: "categoria"
                    }
                ]
            },
            {
                title: "Usuários",
                root: true,
                icon: "fas fa-users",
                page: "usuario",
                bullet: "dot"
            },
            {
                title: "Grupos",
                root: true,
                icon: "fas fa-layer-group",
                page: "grupo",
                bullet: "dot"
            },
        ]
    }
};
